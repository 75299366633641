import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const ProductDesignHop = props => (
  <Layout>
    <Helmet>
      <title>Hop</title>
      <meta
        name="A project on brand strategy and social innovation"
        content="Product Design"
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.hopcover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">PRODUCT DESIGN</span>
            <span className="tag">USER EXPERIENCE</span>
            <span className="tag">UX STRATEGY</span>
            <h1>hop.</h1>
            <h3>
              People have a natural instinct to Play. By adding an element of
              Play into Technology, the Philips branded Hopscoptch game offers a
              fun, interactive and eye-opening experience bringing the brand to
              life. The interaction grabs people’s attention to share those
              brand values and become an informal sponsor of the brand.
            </h3>
          </header>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  Since 2005,Philips they have been working on developing
                  humanitarian propositions that address social and
                  environmental issues affecting the more fragile categories of
                  our societies. They are working on solutions to improve the
                  health and environment of the world’s developing societies.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2016
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 2 weeks
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Brand strategy & Social
                  innovation
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Individual project
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Research, Ideation & Concept
                  Development, Product Design, 3D Modelling & Rendering,
                  Strategy.
                </span>
              </div>
            </div>
            <div className="image">
              <Img
                fluid={props.data.hopchallenge.childImageSharp.fluid}
                alt="Philips Strategy challenge"
                title="Philips Strategy challenge"
              />
            </div>

            <div className="challenge">
              <div className="challenge-left">
                <p>
                  20% of the world’s population do not have access to
                  electricity. That’s 1 in 5 people. Without electricity, most
                  of these people have no other option but to use kerosene lamps
                  to light their homes; and these lamps have many harmful
                  effects.
                </p>
              </div>
              <div className="challenge-right">
                <p>
                  The aim was to Conceptualise a brand strategy for Philips
                  Lighting in order to make people aware of Philips’
                  Sustainability by Design approach.
                </p>
              </div>
            </div>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>
            <div className="process">
              <div className="process-left">
                <p>
                  The design process started with first understanding the
                  Philips Lighting’s mission, brand values, and its vision for
                  supporting communities in need in order to promote healthy
                  people and a sustainable planet. In addition i was keen to get
                  insights into the problems & needs do rural communities in the
                  developing nations are facing especially when it comes to
                  shortages of electricity.
                </p>
              </div>
              <div className="process-right">
                <p>
                  It was vital to find an effective way to bring this problem to
                  light to the urban population, so that they would be aware of
                  it and responsive to it. In order to do this I went to city
                  centre and observed people’s behaviours. Any one point on a
                  busy street can receive up to 50,000 steps a day, so all that
                  foot traffic can be turned it into something useful – like
                  energy.
                </p>
              </div>
            </div>

            <Img
              className="project-process-images"
              fluid={props.data.hopprocess.childImageSharp.fluid}
              alt="Philips strategy process"
              title="Philips strategy process"
            />

            <p className="full-text">
              One of the key insights gained was that people in the city centre
              are stressed & preoccupied in their own world leaving little time
              or thought for others in need. There was a need to come up with an
              innovative solution that would make the people take some time out,
              have fun and at the same time learn something new, all but in a
              simple & natural way. Further research was carried out to see how
              different technologies can be used to bring about
              action/interaction among the people in their everyday street
              context.
            </p>
          </section>
          <section className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>outcome</h2>

              <div className="outcome">
                <div className="outcome-left">
                  <p>
                    The Philips branded Hopscoptch game on a street footpath is
                    made up of Modular Tile Flooring System & an outdoor
                    advertising signage. People see the Hopscotch game while
                    walking on the street. Their natural instinct to “play”
                    kicks-in and they start hopping on it.
                  </p>
                  <p>
                    By playing the hopscotch game, the applied pressure on the
                    modular tiles from the hopping action can be used to produce
                    electricity by using the piezoelectric effect. The outdoor
                    advertising signage lights up when the person hops on the
                    modular tiles, this indicates to the user that they have
                    generated energy.
                  </p>
                </div>
                <div className="outcome-right">
                  <p>
                    Only a small amount of energy produced is used to make the
                    advertising signage light up. The rest of the energy is
                    stored below to be sent to the mains.
                  </p>
                  <p>
                    The lit-up advertising sign shows a picture of a family that
                    has an access to electricity at home thanks to an initiative
                    taken by Philips. This action creates an emotional &
                    personal connection between the Philips brand and the
                    consumer. It provides a sense of purpose to the consumers
                    that they are contributing to the betterment of the
                    community by their hopping action.
                  </p>
                </div>
              </div>

              <h3>
                By offering a playful environment, in a context where people are
                generally stressed; Philips can engage with the consumer and
                bring a smile on their faces. The meaningful interaction has
                puts the consumers at the center of their experience, thus
                grabbing their attention and bringing that person into the brand
                world, to share those brand values and to become an informal
                sponsor of the brand.
              </h3>
            </div>
            <Img fluid={props.data.hopoutcome.childImageSharp.fluid} />
          </section>

          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to all
              <br />
              <a href="/work"> my work</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/product-design-household-dustbins">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">blinky, clyde, inky, pinky.</div>
                <div className="text">
                  A family of household dustbins whose aesthetic appearance can
                  be customised to user's preferences.
                </div>
                <div className="tags">
                  <ul>
                    <li>PRODUCT DESIGN</li>
                    <li>USER EXPERIENCE</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-tecne">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">tecne.</div>
                <div className="text">
                  Next gen combat training with an underglove fitted with
                  sensors & an app that gives precise feedback to improve.
                </div>
                <div className="tags">
                  <ul>
                    <li>UX STRATEGY</li>
                    <li>INTERACTION DESIGN</li>
                    <li>DIGITAL UX/UI</li>
                    <li>PRODUCT DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProductDesignHop

export const fluidHopImage = graphql`
  fragment fluidHopImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    hopcover: file(
      relativePath: { eq: "projects/product/hop/cover/hop-cover-image.jpg" }
    ) {
      ...fluidHopImage
    }
    hopchallenge: file(
      relativePath: { eq: "projects/product/hop/challenge/hop-challenge.jpg" }
    ) {
      ...fluidHopImage
    }
    hopprocess: file(
      relativePath: {
        eq: "projects/product/hop/process/02_hop-strategy-idea.jpg"
      }
    ) {
      ...fluidHopImage
    }
    hopoutcome: file(
      relativePath: { eq: "projects/product/hop/outcome/04_hop-outcome.jpg" }
    ) {
      ...fluidHopImage
    }
    project1: file(relativePath: { eq: "projects/2.png" }) {
      ...fluidHopImage
    }
    project2: file(relativePath: { eq: "projects/15.png" }) {
      ...fluidHopImage
    }
  }
`
